import { Footnotes, FootnotesProvider, FootnoteRef as Ref } from "../../footnotes"
import { graphql, useStaticQuery } from "gatsby"

import FootnotesBackLink from "../../components/FootnotesBackLink"
import Layout from "../../components/Layout"
import ModelList from "../../components/ModelList"
import React from "react"
import Seo from "../../components/seo"

const VintagePage = () => {

  const data = useStaticQuery(graphql`
  {
    vintage: allMdx(
      sort: {order: ASC, fields: frontmatter___model}
      filter: {frontmatter: {collections: {eq: "vintage", in: "electric", nin: "basses"}}}
    ) {
      nodes {
        ...modelData
      }
    }
  
  }
    
  `);

  const vintage = data?.vintage?.nodes.map((x) => x.frontmatter);
 

  return (
    <Layout >
      <Seo title="Vintage Talman guitars" />

      <FootnotesProvider>
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading"><Ref description="The term &quot;vintage&quot; might feel strange to use when referring to guitars built in the 90s, but keep in mind the earliest production models were made in mid to late 1993! We are quickly approaching the 30th anniversary.">Vintage</Ref><br /> Talman guitars</h1>
        <h2 className="text-base lg:text-xl text-gray-400">The original run of Talman electric guitars (1994-1998)</h2>
        
        <section className="flex flex-col items-center">
          <ModelList list={vintage} />

          <h3 className="text-xl">more coming soon!</h3>
        </section>
        
      </div>

      <Footnotes BackLink={FootnotesBackLink} />
      </FootnotesProvider>
    </Layout>
  )
}

export default VintagePage